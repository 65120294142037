import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql, Link } from "gatsby"
import React from "react"
import { Footer } from "../components/Footer"
import { Header } from "../components/Header"
import "../css"
import "../scss/index.scss"
import locales from "../translate/locales"

export const data = graphql`
  query($blogId: String!) {
    contentfulBlogPosts(id: { eq: $blogId }) {
      id
      title
      description
      postDate
      postType
      author
      image {
        file {
          url
        }
      }
      content {
        json
      }

      reletedPost {
        title
        description
        postDate
        id
        image {
          file {
            url
          }
        }
      }
      tags {
        tag
      }
    }
  }
`

const blogView = (props: any) => {
  const blogData = props.data.contentfulBlogPosts

  const locale = props.pageContext.locale
  const texts = locales[locale as "en"]

  return (
    <div>
      <Header lang={locale} />

      <section className="blog-standard-area gray-bg pt-80 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="blog-standard-2">
                <div className="single-blog-standard  mt-40">
                  <div className="blog-dteails-content bg-white">
                    <div className="blog-details-top">
                      <span style={{ margin: "12px 0px" }}>
                        {blogData.postType}
                      </span>

                      <div className="blog-details-bath">
                        <img
                          src={
                            blogData.image !== null
                              ? blogData.image.file.url
                              : "/images/blogNotSelect.jpeg"
                          }
                          alt="blog-details"
                        />

                        <h2 className="title">{blogData.title}</h2>

                        <h3 className="author"> {blogData.author} </h3>

                        <div className="blog-Content">
                          {documentToReactComponents(blogData.content.json , {})}
                        </div>
                      </div>
                    </div>

                    <div className="blog-details-bar d-flex justify-content-between mt-40 mb-50">
                      <div className="blog-tags">
                        <h4 className="title"> Tags</h4>
                        <ul>
                          {blogData.tags.map((tg: any) => {
                            return (
                              <li>
                                <a href="#">{tg.tag}</a>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                      <div className="blog-social text-right">
                        <h4 className="title" style={{ textAlign: "left" }}>
                          Social Share
                        </h4>
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-typo3"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-staylinked"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-tumblr"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="blog-details-releted-post mt-45">
                      <h4 className="title">Releted Post</h4>
                      <div className="row">
                        {(blogData.reletedPost || []).map((post: any) => {
                          return (
                            <div className="col-lg-6 col-md-6">
                              <div className="blog-details-releted-item mt-30">
                                <div className="releted-thumb">
                                  <img src={post.image.file.url} alt="" />
                                </div>
                                <div className="releted-content">
                                  <Link to={`/blogView/${post.id}`}>
                                    <h4 className="title">{post.title}</h4>
                                  </Link>
                                  <p>{post.description}</p>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="blog-sidebar ml-10">
                <div className="blog-side-about white-bg mt-40">
                  <div className="about-title">
                    <h4 className="title">Never Miss News</h4>
                  </div>
                  <div className="blog-social-content mt-35 text-center">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-behance"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer lang={locale} />
    </div>
  )
}

export default blogView
